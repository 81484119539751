import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { store } from "../../shared";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { useTranslation } from "react-i18next";
import {
  setModeDelivery,
  setModesModal,
} from "../../shared/slice/ModalMode/ModalMode";

import { setModalPrincipal } from "../../shared/slice/ModalLogin/ModalLoginSlice";
import {
  getProductByStoreByMode,
  getProductByStoreByModeWithout,
} from "../../shared/slice/restaurant/RestaurantService";
// import {setModeName} from "../../../../shared/slice/restaurant/RestaurantSlice";
import {
  setLocation,
  setModeId,
  setModeName,
  setModeSelectedId,
  setOutOfRange,
  setProduct,
} from "../../shared/slice/restaurant/RestaurantSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { resetPromo } from "../../shared/slice/promos/PromosSlice";
import { setOrder } from "../../shared/slice/order/OrderSlice";
import { disconnects } from "../../shared/slice/auth/AuthSlice";
import { setModelGuest } from "../../shared/slice/ModalLogin/ModalLoginSlice";
import CryptoJS from "crypto-js";
export default function ModalModes() {
  const location = useLocation();
  const { delivery } = location.state || {};

  //const orders = useSelector((state) => state.order.order);

  const { id } = useParams();
  const loggedInGuest = useSelector(
    (state) => state.authentification.loggedInGuest
  );
  const loggedInUser = useSelector(
    (state) => state.authentification.loggedInUser
  );
  const { t } = useTranslation();

  const modeSelectedId = useSelector(
    (state) => state.restaurant.modeSelectedId
  );
  const restaurantSelected = useSelector(
    (state) => state.restaurant.restaurantSelected
  );
  const modes = useSelector((state) => state.restaurant.mode);
  const modesModal = useSelector((state) => state.modalMode.modesModal);
  const navigate = useNavigate();

  const [selectedMode, setSelectedMode] = useState("");
  const orders = useSelector((state) => state.order.order);

  {
    /*----encryption / decryption functions----*/
  }

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), "secret_key").toString();
  };

  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, "secret_key");
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);

      // Check if the decrypted text is valid JSON
      if (!decryptedText) {
        throw new Error("Decryption failed or returned empty string.");
      }

      return JSON.parse(decryptedText);
    } catch (error) {
      console.error("Error during decryption or parsing:", error);
      return null; // or handle the error as appropriate
    }
  };

  useEffect(() => {
    if (modesModal) {
      store.dispatch(setScroll({ scroll: 1 }));
    }
  }, [modesModal]);

  const handleCloseModalModes = () => {
    store.dispatch(setModesModal({ modesModal: false }));
    store.dispatch(setScroll({ scroll: 0 }));

    // document.documentElement.style.overflow = 'auto';
  };

  const handleModeButtonClick = (mode) => {
    setSelectedMode(mode.mode._id);
    store.dispatch(setModeName({ modeName: mode.mode.name }));
  };

  const deleteItemCompletely = (productId) => {
  

    const localStorageKey = `QuantityInCart_ForStoreWithId${id}_ForProductWith${productId}`;
    localStorage.removeItem(localStorageKey);
   
  };



  const calculateHT = (priceWithTax, taxRate) => {
    const price = parseFloat(priceWithTax);
    const tax = parseFloat(taxRate) / 100;

    const calculatedHT = price / (1 + tax);
    // Format to 2 decimal places
    return calculatedHT;
  };

  const handleConfirmationButtonClick = async () => {
    store.dispatch(setOutOfRange({ outOfRange: false }));
    const isLivraisonMode =
      modes.find((m) => m.mode._id === selectedMode)?.mode.name === "Delivery";
    // console.log("selectedMode",selectedMode);

    if (!selectedMode) return;
    // if (selectedMode === modeSelectedId) {
    //     handleCloseModalModes()  66
    // } else {


    if (isLivraisonMode) {
if(loggedInUser===undefined && loggedInGuest===undefined){
   store.dispatch(setModalPrincipal({ modalPrincipal: true }));
  
}
      store.dispatch(setLocation(null));
      const aa = true;
      store.dispatch(setModeDelivery({ modeDelivery: aa }));
      store.dispatch(setOutOfRange({ outOfRange: false }));
    }
    let res3;
    if (isLivraisonMode) {

      res3 = await getProductByStoreByModeWithout(
        restaurantSelected._id,
        selectedMode
      );
    } else {
      res3 = await getProductByStoreByMode(
        restaurantSelected._id,
        selectedMode
      );
    }

    store.dispatch(setProduct({ product: res3 }));

    const productsResult = res3;
    store.dispatch(setModeSelectedId({ modeSelectedId: selectedMode }));

    //delete order
    store.dispatch(setOrder({ order: [] }));

    const cryptedOrder = localStorage.getItem("OrderStoreWithID" + id);
    if (cryptedOrder) {
      const orders = decryptData(cryptedOrder);
      const allOrdersWillDelete03 = [];
      if (orders) {
        const NewOrders = [];

        orders.forEach((order, index) => {
          
          const product = productsResult.find(
            (product) => product._id === order.product.id
          );
        
          if (
            product.availability === false ||
            product.availabilitys[0].availability === false
          ) {
            const OrdersWillDelete = {
              orderKey: order.orderKey,
              product: order.product.id,
            };
            allOrdersWillDelete03.push(OrdersWillDelete);
            return;
          }

          const PriceBySize = product.size.find(
            (size) => size.name === order.product.size
          );
          const PriceByMode = PriceBySize.PriceOb.find(
            (mode) => mode.ModeID === selectedMode
          );

          let priceHtForAllOptions = 0;
          let priceForAllOptions = 0;
          let arraytax = [];
          const options22 = order.options;
          options22.forEach((option, index) => {
            priceHtForAllOptions = priceHtForAllOptions + option.priceHt;
            priceForAllOptions = priceForAllOptions + option.price;
            const arraytt = {
              tax: option.tax,
              price: option.price_opt,
            };
            arraytax.push(arraytt);
          });
          const arraytt = {
            tax: order.product.tax,
            price: PriceByMode.PriceByMode,
          };
        
          arraytax.push(arraytt);
     
          const taxTotals = arraytax.reduce((acc, { tax, price }) => {
            const validPrice = price ?? 0; // Handle null price as 0
            if (!acc[tax]) {
              acc[tax] = 0;
            }
            acc[tax] += validPrice * (tax / (100 + tax)); // Add tax value for the specific rate
            return acc;
          }, {});

          // Result as an array or keep it as an object
      

          const htProduct = parseFloat(
            calculateHT(PriceByMode.PriceByMode, order.product.tax)
          );
       
          priceHtForAllOptions = priceHtForAllOptions + htProduct;
          priceForAllOptions = priceForAllOptions + PriceByMode.PriceByMode;
        
          const newProduct = {
            description: product.description,
            id: product._id,
            image: product.image,
            name: product.name,
            price: PriceByMode.PriceByMode,
            priceHt: htProduct,
            size: order.product.size,
            tax: order.product.tax,
            taxId: order.product.taxId,
            withOptions: order.product.withOptions,
          };
     
          const newTxwitd = [];
          for (const key in order.taxes) {
            if (order.taxes.hasOwnProperty(key)) {
              // Check if the property belongs to the object

              for (const key02 in order.taxesId) {
                if (order.taxesId.hasOwnProperty(key02)) {
                  if (order.taxes[key] === order.taxesId[key02]) {
                    const id = {
                      _id: key02,
                      value: key,
                    };
                    newTxwitd.push(id);
                  }
                }
              }  
            }
          }


          const newtaxesIdFinal =[]
        
          for (const key in taxTotals) {

            for (const key02 in newTxwitd) {
   
                 if(key===newTxwitd[key02].value){
              
                  const pp = {
                    [newTxwitd[key02]._id]: taxTotals[key],
                };
                newtaxesIdFinal.push(pp);
                 }

            }
          }


        

          const newOrder = {
            index: order.index,
            note: order.note,
            options: order.options,
            orderKey: order.orderKey,
            price: priceForAllOptions * order.quantity,
            priceHt: priceHtForAllOptions * order.quantity,
            product: newProduct,
            quantity: order.quantity,
            taxes: taxTotals,
            taxesId: newtaxesIdFinal,
            unitePrice: priceHtForAllOptions,
            unitePriceHt: priceForAllOptions,
          };
    
          NewOrders.push(newOrder);
        });
      
        store.dispatch(setOrder({ order: NewOrders }));
        const encryptedOrder = encryptData(NewOrders);
        localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
      }

      if (allOrdersWillDelete03.length > 0) {
        allOrdersWillDelete03.forEach(({ product, orderKey }) => {
          deleteItemCompletely(product, orderKey); // Call the delete function
        });
      }
    }
    
    // if (cryptedOrder){
    
    //   const orders = decryptData(cryptedOrder);
    
    //   const allOrdersWillDelete02 = [];
    //   const updatedOrders = orders.reduce((newOrders, order) => {
    //     const product = productsResult.find(p => p._id === order.product.id);
    //     if (!product) return newOrders; // Skip processing if product not found
    
    //     // Handle unavailable products
    //     if (!product.availability || !product.availabilitys[0]?.availability) {
    //       allOrdersWillDelete02.push({ orderKey: order.orderKey, product: order.product.id });
    //       return newOrders;
    //     }
    
    //     const priceBySize = product.size.find(size => size.name === order.product.size);
    //     const priceByMode = priceBySize?.PriceOb.find(mode => mode.ModeID === selectedMode);
    //     if (!priceByMode) return newOrders; // Skip if no pricing info found
    
    //     const options = order.options || [];
    //     const optionPrices = options.reduce(
    //       (totals, option) => {
    //         totals.priceHt += option.priceHt || 0;
    //         totals.price += option.price || 0;
    //         totals.taxes.push({ tax: option.tax, price: option.price_opt });
    //         return totals;
    //       },
    //       { priceHt: 0, price: 0, taxes: [] }
    //     );
    
    //     const baseTax = {
    //       tax: order.product.tax,
    //       price: priceByMode.PriceByMode,
    //     };
    //     optionPrices.taxes.push(baseTax);
    
    //     const taxTotals = optionPrices.taxes.reduce((acc, { tax, price }) => {
    //       const validPrice = price || 0;
    //       acc[tax] = (acc[tax] || 0) + validPrice * (tax / (100 + tax));
    //       return acc;
    //     }, {});
    
    //     const htProduct = parseFloat(calculateHT(priceByMode.PriceByMode, order.product.tax));
    //     const newProduct = {
    //       ...product,
    //       price: priceByMode.PriceByMode,
    //       priceHt: htProduct,
    //       size: order.product.size,
    //       tax: order.product.tax,
    //       taxId: order.product.taxId,
    //       withOptions: order.product.withOptions,
    //     };
    
    //     const newOrder = {
    //       ...order,
    //       price: (optionPrices.price + priceByMode.PriceByMode) * order.quantity,
    //       priceHt: (optionPrices.priceHt + htProduct) * order.quantity,
    //       taxes: taxTotals,
    //       product: newProduct,
    //       unitePrice: optionPrices.priceHt + htProduct,
    //       unitePriceHt: optionPrices.price + priceByMode.PriceByMode,
    //     };
    
    //     return [...newOrders, newOrder];
    //   }, []);
    
    //   // Update store and localStorage
    //   store.dispatch(setOrder({ order: updatedOrders }));
    //   localStorage.setItem(`OrderStoreWithID${id}`, encryptData(updatedOrders));
    
    //   // Handle deleted orders
    //   if (allOrdersWillDelete02.length > 0) {
    //     allOrdersWillDelete02.forEach(({ product, orderKey }) => deleteItemCompletely(product, orderKey));
    //   }
    
    // }
    handleCloseModalModes();

    // if (cryptedOrder) {
    //   const orders02 = decryptData(cryptedOrder);

    //   orders02.forEach((order, index) => {
    //     const OrdersWillDelete ={
    //       orderKey: order.orderKey,
    //       product: order.product.id
    //     }
    //     allOrdersWillDelete02.push(OrdersWillDelete);

    //   })
    //   allOrdersWillDelete02.forEach(({ product, orderKey }) => {

    //     deleteItemCompletely(product, orderKey); // Call the delete function
    // });

    // }

    return;
    // Fin delete order

    const allOrdersWillDelete = [];
    if (cryptedOrder) {
      const orders = decryptData(cryptedOrder); // Convert to JavaScript object
      let newOrderList = [];
      let allOrders = [];
      if (orders) {
        orders.forEach((order, index) => {
          const product = productsResult.find(
            (product) => product._id === order.product.id
          );

          if (product) {
            product.availabilitys.forEach((availability) => {
              if (availability.availability) {
                if (order.mode !== selectedMode) {
                  let updatedOrder = { ...order };
                  const sizze = product.size.find(
                    (size) => size.name === order.product.size
                  );
                  const priceHtPF = sizze.PriceOb.find(
                    (size) => size.ModeID === selectedMode
                  );

                  const priceHt = priceHtPF.PriceByMode;
                  updatedOrder.unitePrice = priceHt;
                  updatedOrder.price = priceHt;
                  updatedOrder.product.price = priceHt;
                  updatedOrder.product.priceHt =
                    priceHt / (1 + product.taxes[0].tax.rate / 100);
                  updatedOrder.product.tax = product.taxes[0].tax.rate;
                  updatedOrder.product.taxId = product.taxes[0].tax._id;

                  const previousModeObject = modes.find(
                    (m) => m.mode._id === order.mode
                  );
                  if (previousModeObject.mode.applicationType === "product") {
                    updatedOrder.price =
                      updatedOrder.price -
                      (previousModeObject.mode.frais !== null
                        ? previousModeObject.mode.frais
                        : updatedOrder.product.price *
                          (previousModeObject.mode.taux / 100)) *
                        updatedOrder.quantity;
                    updatedOrder.unitePrice =
                      updatedOrder.unitePrice -
                      (previousModeObject.mode.frais !== null
                        ? previousModeObject.mode.frais
                        : updatedOrder.product.price *
                          (previousModeObject.mode.taux / 100)) *
                        updatedOrder.quantity;
                  }

                  const actuelModeObject = modes.find(
                    (m) => m.mode._id === selectedMode
                  );

                  if (actuelModeObject.mode.applicationType === "product") {
                    updatedOrder.price =
                      updatedOrder.price +
                      (actuelModeObject.mode.frais !== null
                        ? actuelModeObject.mode.frais
                        : updatedOrder.product.price *
                          (actuelModeObject.mode.taux / 100)) *
                        updatedOrder.quantity;
                    updatedOrder.unitePrice =
                      updatedOrder.unitePrice +
                      (actuelModeObject.mode.frais !== null
                        ? actuelModeObject.mode.frais
                        : updatedOrder.product.price *
                          (actuelModeObject.mode.taux / 100)) *
                        updatedOrder.quantity;
                  }
                  const allOptions = product.optionGroups.flatMap(
                    (optionGroup) => optionGroup.options
                  );
                  let pht = 0;
                  if (updatedOrder.options.length > 0) {
                    updatedOrder.options.forEach((option, index) => {
                      const optionFound = allOptions.find(
                        (opt) => opt._id === option.id
                      );
                      if (optionFound) {
                        const priceHt =
                          optionFound.price /
                          (1 + optionFound.option.tax / 100);
                        updatedOrder.options[index].priceHt = priceHt;
                        pht += priceHt;
                        updatedOrder.options[index].tax =
                          optionFound.option.tax;
                        updatedOrder.options[index].taxId =
                          optionFound.option.id_tax;
                        updatedOrder.options[index].taxPrice =
                          optionFound.price - priceHt;
                      }
                      if (option.options.length > 0) {
                        const subOption = optionFound.subOptionGroup;
                        if (subOption) {
                          option.options.forEach((subOpt, subIndex) => {
                            const allSubOpt = subOption.flatMap(
                              (sub) => sub.options
                            );
                            const subOptFound = allSubOpt.find(
                              (sub) => sub._id == subOpt.id
                            );
                            if (subOptFound) {
                              const price = subOptFound.price;
                              //--------------------------------verify tax rate in subOptions ---------------------------------------
                              const taxRate =
                                subOptFound.option &&
                                typeof subOptFound.option.tax === "number"
                                  ? subOptFound.option.tax
                                  : 0;
                              const priceHt2 = price / (1 + taxRate / 100);

                              updatedOrder.options[index].priceHt = priceHt2;
                              pht += priceHt2;
                              updatedOrder.options[index].options[
                                subIndex
                              ].tax = subOptFound.option?.tax;
                              updatedOrder.options[index].options[
                                subIndex
                              ].taxId = subOptFound.option.id_tax;
                              updatedOrder.options[index].options[
                                subIndex
                              ].taxPrice = price - priceHt2;
                            }
                          });
                        }
                      }
                    });
                  }
                  updatedOrder.priceHt =
                    (updatedOrder.product.priceHt + pht) * order.quantity;
                  updatedOrder.mode = selectedMode;
                  newOrderList.push(updatedOrder);
                  allOrders.push(updatedOrder);
                } else {
                  console.log("same mode");
                  newOrderList.push(order);
                  allOrders.push(order);
                }
              } else {
                allOrders.push(order);
              }
            });

            if (
              product.availability === false ||
              product.availabilitys[0].availability === false
            ) {
              const OrdersWillDelete = {
                orderKey: order.orderKey,
                product: order.product.id,
              };
              allOrdersWillDelete.push(OrdersWillDelete);
            }
          }
        });
      }
      store.dispatch(setOrder({ order: newOrderList }));
      allOrdersWillDelete.forEach(({ product, orderKey }) => {
        deleteItemCompletely(product, orderKey); // Call the delete function
      });
      const encryptedOrder = encryptData(allOrders);
      localStorage.setItem(`OrderStoreWithID${id}`, encryptedOrder);
    }

    handleCloseModalModes();
    // store.dispatch(setOrder({ order: [] }));
    // store.dispatch(resetPromo());

    store.dispatch(setModeId({ modeId: selectedMode }));
    setSelectedMode(null); // Reset selected mode after changing the mode
    // }
  };
  // const orders = useSelector((state) => state.order.order)
  const orderspromos = useSelector((state) => state.promos.selectedPromos);

  const [isSwitchedOn, setIsSwitchedOn] = useState(false);
  return (
    <StyledModalMode
      isOpen={modesModal}
      // onRequestClose={handleCloseModalModes}
    >
      {/*{products&&selectedMode !== null && (
                <ButtonClose onClick={handleCloseModalModes}>
                    <ClearIcon style={{ fontSize: "27px" }} />
                </ButtonClose>
            )}
        */}

      {isSwitchedOn === true ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: "2rem", fontSize: "18px" }}>
            {t("Switching modes will clear your cart.")}
            <br />
            {t("Would you like to proceed?")}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <ButtonStyled
              onClick={() => {
                handleConfirmationButtonClick();

                setIsSwitchedOn(false);
              }}
            >
              {t("Yes")}
            </ButtonStyled>
            <ButtonStyled
              style={{ marginLeft: "10px" }}
              onClick={() => setIsSwitchedOn(false)}
            >
              {t("No")}
            </ButtonStyled>
          </div>
        </div>
      ) : (
        <div>
          <h3>{t("Select Your Mode")}</h3>

          <ButtonGroup>
            {modes?.map((mode) =>
              // Check if the mode is "Delivery"
              (mode.mode.name === "Delivery" &&
                delivery === undefined &&
                delivery !== false) ||
              // Check if the mode is not "Delivery"
              mode.mode.name !== "Delivery" ? (
                <ButtonStyled
                  key={mode.mode._id}
                  onClick={() => handleModeButtonClick(mode)}
                  isSelected={selectedMode === mode.mode._id}
                >
                  {/* Render different icons based on the mode name */}
                  {mode.mode.name === "Delivery" ? (
                    <span>
                      <DeliveryDiningIcon style={{ fontSize: "30px" }} />
                    </span>
                  ) : mode.mode.name === "Takeaway" ? (
                    <span>
                      <ShoppingBagIcon style={{ fontSize: "30px" }} />
                    </span>
                  ) : (
                    <span>
                      <RestaurantMenuIcon style={{ fontSize: "30px" }} />
                    </span>
                  )}
                  {/* Render the mode name */}
                  <h6>{t(mode.mode.name)}</h6>
                </ButtonStyled>
              ) : null
            )}
          </ButtonGroup>

          <ConfirmationButtonStyled
            selectedMode={selectedMode}
            onClick={() => {
              // if ((orders.length > 0 || orderspromos.length > 0) && selectedMode !== modeSelectedId) {
              //   setIsSwitchedOn(true);
              // } else {
              handleConfirmationButtonClick();
              // }
            }}
          >
            {t("Confirm")}
          </ConfirmationButtonStyled>
        </div>
      )}
    </StyledModalMode>
  );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}

const StyledModalMode = styled(ReactModalAdapter).attrs({
  modalClassName: "ModalModes",
  overlayClassName: "OverlayModes",
})`
  .ModalModes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    height: 280px;
    width: 400px;
    text-align: center;
    outline: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  @media (max-width: 768px) {
    .ModalModes {
      width: 95%;
      min-width: 90%;
    }
  }

  .OverlayModes {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  border-radius: 15px;
  margin-bottom: 25px;
  margin-top: 35px;
`;
const ButtonStyled = styled.button`
  appearance: none;
  margin: 5px;

  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 5px 10px 5px;
  border: 2px solid
    ${({ isSelected }) => (isSelected ? "transparent" : "#f6f6f6")};
  outline: none;
  border-radius: 15px;
  background-color: ${({ isSelected }) => (isSelected ? "#eb1700" : "#f6f6f6")};
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#eb1700")};
  font-size: 15px;
  cursor: pointer;
  padding-right: 0px;
  width: 110px;
  //   display: flex;
  //   justify-content: space-evenly;
  //   align-items: center;
`;

const ButtonClose = styled.button`
  position: absolute;
  top: 5px;
  right: 3px;
  padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;
const ConfirmationButtonStyled = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px;
  border: 2px solid ${(props) => (props.selectedMode ? "#eb1700" : "#6b6b6b")};
  outline: none;
  border-radius: 15px;
  background-color: ${(props) => (props.selectedMode ? "#eb1700" : "#6b6b6b")};
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding-right: 0px;
  width: 100%;
`;
