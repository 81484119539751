import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StoreBySpeciality from './Home/StoreScreen/StoreBySpeciality';
import { useTranslation } from 'react-i18next';
import { setScroll } from '../shared/slice/scroll/ScrollSlice';
import LogoEatorder from '../assets/images/eatorder-logo.png';
import  Logo  from '../assets/images/eatorder-logo-white.png';
import { setModalPrincipal, setModalSignup, setModelGuest } from '../shared/slice/ModalLogin/ModalLoginSlice';
import { store } from '../shared';
import ModalSignup from '../components/Modals/ModalSignup';
import { useDispatch, useSelector } from 'react-redux';
import ModalLogin from '../components/Modals/ModalLogin';
import ModalEmailSend from '../components/Modals/ModalEmailSend';
import Notification from '../components/Notification/notification';
import SideNavBar from '../components/Navbar/SideNavBar';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS
import { setEditOrder, setMenu, setMode, setModeSelectedId, setOpenModal, setRestaurantSelected } from '../shared/slice/restaurant/RestaurantSlice';
import { disconnects } from '../shared/slice/auth/AuthSlice';
import ModalPrincipal from '../components/Modals/ModalPrincipal';
import { setRootLoading } from '../shared/slice/rootSlice';
import { setPromos } from '../shared/slice/promos/PromosSlice';
const FirstPage = () => {
  const loggedInGuest = useSelector((state) => state.authentification.loggedInGuest);

    const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [unreadCount, setUnreadCount] = useState(0);



    // Utilisation de useEffect pour initialiser le scroll
    useEffect(() => {
      store.dispatch(setRootLoading(false));
      store.dispatch(setPromos({ promos:  [] }));
      // if(loggedInGuest && loggedInGuest!=false){
      //   store.dispatch(disconnects());
      //   store.dispatch(setModelGuest({ modelGuest: false }));
      //  }

       dispatch(setMode({ }));
        store.dispatch(setScroll({ scroll: 0 }));
        store.dispatch(setModeSelectedId({ modeSelectedId: "" }));
        store.dispatch(setRestaurantSelected({ }));
        store.dispatch(setMenu({}));
    }, []);



    store.dispatch(setOpenModal({
      product: undefined,
      open: false,
    }));
    store.dispatch(setEditOrder(
      {
         open: false,
         objectOptions:[],
         checkedIndices: [],
         checkedOptions: [],
         counter:{},
         orderQuantity:0,
         note:undefined,
         size:""
     }
     ));
    const SignIn = () => {
      store.dispatch(setModalPrincipal({ modalPrincipal: true }));    }
    const SignUp = () => {
        store.dispatch(setModalSignup({ modalSignup: true }));
    }
    return (
        <div>
            <Container>
                <LogoImage src={LogoEatorder} alt="Eatorder Logo" />
                {!isLoggedIn &&  <div>
                    <StyledAnchor
                    onClick={() => SignIn()}
                    >
                        {t('Sign In')}
                    </StyledAnchor>
                    <StyledAnchor2
                     onClick={() => SignUp()}
                    >
                        {t('Sign Up')}
                    </StyledAnchor2>
                </div>
                   }
                   {isLoggedIn && (
                    <div>
                  <Notification style={{backgroundColor:"green!important" }}/>
                  {unreadCount >0 && (
                    <CardBadge>
                      <div>{unreadCount}</div>
                    </CardBadge>
                  )}
                 
                <SideNavBar />
             </div>
            )}
                               </Container>
            <div>
                <ModalLogin />
                <ModalSignup />
                <ModalPrincipal/>                 
                 <ModalEmailSend />
            </div>
            <StoreBySpeciality />
          
            {/* <Footer>azezae
                <LogoImageFooter  src={Logo} alt="Eatorder Logo" /> */}
  <MDBFooter style={{fontSize:"1rem"}} bgColor='dark' className='text-center text-lg-start text-light'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom border-light'>
        <div className='me-5 d-none d-lg-block'>
          <span>{t("Get connected with us on social networks")}:</span>
        </div>
        <div>
          <a href='' className='me-4 text-reset text-light'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset text-light'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset text-light'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset text-light'>
            <MDBIcon fab icon="instagram" />
          </a>
        </div>
      </section>
      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4' style={{display: 'flex',
    flexDirection:'column',
    alignItems:'center'}}>
              <h6 className='text-uppercase fw-bold mb-4'>
              <LogoImageFooter  src={Logo} alt="Eatorder Logo" />
              </h6>
              <p>
              {t("Craving something delicious? Order your favorite meals from our platform and enjoy fast, easy, and reliable food delivery. Discover a wide variety of cuisines, exclusive deals, and hassle-free ordering. Satisfaction guaranteed with every bite.")}

              </p>
            </MDBCol>
            {/* <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='#!' className='text-reset text-light'>
                  Pricing
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset text-light'>
                  Settings
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset text-light'>
                  Orders
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset text-light'>
                  Help
                </a>
              </p>
            </MDBCol> */}
            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                  11 arrondissement paris , 75011, France
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                contact@eatorder.fr
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> + 01 234 567 88
              </p>
              <p>
                <MDBIcon icon="print" className="me-3" /> + 01 234 567 89
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div className='text-center p-' style={{ backgroundColor: 'rgba(255, 255, 255, 0.05)'}}>
        <p> <b>Makseb Solutions</b></p>
      </div>
    </MDBFooter>
            {/* </Footer> */}
        </div>
    );
};
const Footer = styled.div`
padding:0.7rem;
background-color:#414141;
color:white;
height:300px;
`;
const CardBadge = styled.h6`
min-width: 20px;
min-height: 20px;
  background-color: #000;
  border-radius: 50%;
  text-align: center;
  margin-left: -10px;
  color: #fff;
`;
const LogoImage = styled.img`
    height: 18px; 
    margin-left:8px;
    @media (min-width: 768px) {
        height: 25px; 
        margin-left:40px;
    }
`;
const LogoImageFooter = styled.img`
    height: 18px; 
    @media (min-width: 768px) {
        height: 25px; 
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 8px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white; /* Ensure it has a background color */
    z-index: 1000; /* Ensure it appears above other elements */
`;
const StyledAnchor = styled.button`
    padding: 8px 20px;
    background-color: #EB1700;
    color: white;
    text-decoration: none;
    border-radius: 25px;
    text-align: center;
    margin-right:5px;
    @media (max-width:336px){
    padding: 4px 10px;
    }
`;
const StyledAnchor2 = styled.button`
    padding: 8px 20px;
    color: #363332;
    text-align: center;
    background-color: #E6DDDC;
    border-radius: 25px;
    margin-right:40px;
    @media (max-width:336px){
    padding: 4px 10px;
    }
`;
export default FirstPage;